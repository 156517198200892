import * as React from 'react'
import styled from '../../../styled-components'
import { ContentContainerStyle, HeadlineStyle } from '../../../styles'
import Utils from '../../../lib/Utils'

interface IProps {

}

export default class SkillsComponent extends React.Component<IProps, {}> {
  render () {
    return (<Container>
      <Content>
        <Group>
          <Headline>Webdevelopment</Headline>
          <SkillsGroup>
            <SkillsGroupLabel>Frontend</SkillsGroupLabel>
            <Skill>HTML</Skill>
            <Skill>CSS</Skill>
            <Skill>JavaScript</Skill>
            <Skill>ES6</Skill>
            <Skill>TypeScript</Skill>
            <Skill>CoffeeScript</Skill>
            <Skill>ReactJS</Skill>
            <Skill>Redux</Skill>
            <Skill>MobX</Skill>
            <Skill>Webpack</Skill>
            <Skill>Jest</Skill>
          </SkillsGroup>
          <SkillsGroup>
            <SkillsGroupLabel>Backend</SkillsGroupLabel>
            <Skill>NodeJS</Skill>
            <Skill>Express</Skill>
            <Skill>Ruby</Skill>
            <Skill>Rails</Skill>
            <Skill>PHP</Skill>
            <Skill>Laravel</Skill>
            <Skill>MySQL</Skill>
            <Skill>PostgreSQL</Skill>
            <Skill>Redis</Skill>
          </SkillsGroup>
        </Group>
        <Group>
          <Headline>Softwaredevelopment</Headline>
          <SkillsGroup>
            <Skill>Electron</Skill>
            <Skill>Swift</Skill>
            <Skill>CSharp</Skill>
            <Skill>C / C++</Skill>
          </SkillsGroup>
        </Group>
        <Group>
          <Headline>Gamedevelopment</Headline>
          <SkillsGroup>
            <Skill>Modeling</Skill>
            <Skill>Gamedesign</Skill>
            <Skill>Unity</Skill>
            <Skill>CSharp</Skill>
            <Skill>Blender</Skill>
            <Skill>PIXI.js</Skill>
            <Skill>Phaser</Skill>
          </SkillsGroup>
        </Group>
      </Content>
    </Container>)
  }
}

const Container = styled.div`
  ${ContentContainerStyle};
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
`


const Content = styled.div`
  max-width: 1270px;
  width: 100%;
  color: ${props => props.theme.textColor};
  letter-spacing: 2px;
`

const Group = styled.div`
  margin-bottom: 55px;
  ${Utils.mobileBreakpoint(`
    margin-bottom: 15px;
  `)}
`

const Headline = styled.div`
  ${HeadlineStyle};
  color: ${props => props.theme.greenColor};
  &:before {
    display: inline-block;
    content: '#';
  }
  ${Utils.mobileBreakpoint(`
    margin-bottom: 10px;
    font-size: 20px;
  `)}
`
const SkillsGroup = styled.div`
  ${HeadlineStyle};
  font-size: 32px;
  ${Utils.mobileBreakpoint(`
    font-size: 15px;
    margin-bottom: 10px;
  `)}
`
const SkillsGroupLabel = styled.div`
   display: inline-block;
   color: ${props => props.theme.greenColor};
   margin-right: 15px;
`
const Skill = styled.div`
  display: inline-block;
  margin-right: 15px;
  &:before {
    display: inline-block;
    content: '#';
  }
`
