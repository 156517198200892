export interface IMainTheme {
  backgroundColor: string
  textColor: string
  greenColor: string
}

const MainTheme: IMainTheme = {
  backgroundColor: '#222131',
  textColor: '#FFFFFF',
  greenColor: '#47C9AF'
}

export default MainTheme
