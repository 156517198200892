import { css } from './styled-components'
import Utils from './lib/Utils'

export const ContentContainerStyle = css`
  width: 100%;
  max-width: 1440px;
  margin: auto;
  ${Utils.mobileBreakpoint(`
    padding: 0 20px;
  `)}
`

export const HeadlineStyle = css`
  font-family: 'Avenir', sans-serif;
  font-weight: 900;
  font-size: 72px;
  text-transform: uppercase;
  margin: 0;
  ${Utils.mobileBreakpoint(`
    font-size: 30px;
  `)}
`
