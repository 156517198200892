import React, { Component } from 'react'
import styled, { withTheme } from '../styled-components'
import { MobxRouter } from 'mobx-router'
import HeaderComponent from './shared/HeaderComponent'
import FooterComponent from './shared/FooterComponent'

class AppComponent extends Component {
  render() {
    return (
      <Container>
        <HeaderComponent />
        <MobxRouter />
        <FooterComponent />
      </Container>
    )
  }
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background: ${props => props.theme.backgroundColor};
  display: flex;
  flex-direction: column;
`

export default withTheme(AppComponent)
