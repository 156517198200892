import * as React from 'react'
import styled from '../../../styled-components'
import { ContentContainerStyle, HeadlineStyle } from '../../../styles'

interface IProps {

}

export default class HomeComponent extends React.Component<IProps, {}> {
  render () {
    return (<Container>
      <Content>
        <Headline>Hello,</Headline>
        <Text>
          I'm sash. I'm a <GreenText>full stack software developer</GreenText> and&nbsp;
          <GreenText>open source enthusiast</GreenText> with an affinity for design.
          I'm also part of the <GreenText>VLIGHT.TO VJ Team</GreenText>.
        </Text>
      </Content>
    </Container>)
  }
}

const Container = styled.div`
  ${ContentContainerStyle};
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
`


const Content = styled.div`
  max-width: 910px;
  width: 100%;
  color: ${props => props.theme.textColor};
`

const Headline = styled.h1`
  ${HeadlineStyle};
  color: ${props => props.theme.greenColor};
`

const Text = styled.div`
  font-family: 'Avenir', sans-serif;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 2px;
`

const GreenText = styled.span`
  color: ${props => props.theme.greenColor};
  font-weight: 700;
`
