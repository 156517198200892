import * as React from 'react'
import styled from '../../../styled-components'
import { ContentContainerStyle, HeadlineStyle } from '../../../styles'
import Utils from '../../../lib/Utils'

interface IProps {

}

interface IProjectData {
  image: string
  name: string
  date: string
  description: string
  tools?: string
  url?: string
  rel?: string
}

const ProjectData: IProjectData[] = [
  {
    image: '/images/projects/bloxbox.png',
    name: 'bloxbox.de',
    date: '2020',
    description: 'I recently combined my love for LEGO with my wish to create a physical product and founded bloxbox. At bloxbox we sell personalized mosaics made of building bricks compatible to LEGO.',
    tools: 'Ruby on Rails, TypeScript, React.JS, THREE.JS',
    url: 'https://bloxbox.de'
  },
  {
    image: '/images/projects/savedisc.png',
    name: 'savedisc.de',
    date: '2019',
    description: 'Digitalizing your collection of music CDs is a time consuming and annoying task. I’ve built a little service that people can send their CDs to. I’ve built a robotic arm and a Node.JS application that handles disc conversion and meta tagging and moves the robotic arm to switch discs.',
    tools: 'Ruby on Rails, React.JS, Node.JS, Arduino, 3D printing'
  },
  {
    image: '/images/projects/vlight.png',
    name: 'VLIGHT.TO',
    date: '2015 - today',
    description: 'Since 2015 I’ve been part of the VLIGHT.TO VJ-Team. Over the past 18 years, VLIGHT.TO has travelled around the world, bringing visuals to events and festivals.',
    tools: 'My hands, lots of caffeine, little sleep, lots of traveling.',
    url: 'http://vlight.to'
  },
  {
    image: '/images/projects/vmxr.png',
    name: 'V/MXR',
    date: '2015 - today',
    description: 'V/MXR is the audio-reactive VJing software that we use at VLIGHT.TO. I started building it in 2015 using only web technologies. Since then, its visuals have been seen on events and festivals by more than 500.000 people.',
    tools: 'Electron (JavaScript / Node.JS), React.JS, PIXI.JS, WebGL'
  },
  {
    image: '/images/projects/beatquest.png',
    name: 'beatquest.fm',
    date: '2011, 2018',
    description: 'beatquest.fm is an online multiplayer music quiz. I originally started it in 2011 but took it offline since I didn’t have enough time for it. Now it’s back online.',
    tools: 'Node.JS, Socket.IO, React.JS, TypeORM',
    url: 'https://beatquest.fm'
  },
  {
    image: '/images/projects/cointray.png',
    name: 'CoinTray App',
    date: '2017',
    description: 'CoinTray is a crypto currency balance tracker app for MacOS that runs in the status bar. It allows you to easily check balances of your wallets and exchange platform accounts.',
    tools: 'Swift',
    url: 'https://cointrayapp.com'
  },
  {
    image: '/images/projects/photoeditorsdk.png',
    name: 'PhotoEditorSDK',
    date: '2014 - 2017',
    description: 'I started PhotoEditorSDK together with the team at 9elements back in 2014. Over the next years, we gained multiple Fortune 500 customers such as Amazon, HP and Shutterstock. I was responsible for the HTML5 editor. I left the team in 2017 for new adventures.',
    tools: 'React.JS, WebGL, Node.JS, TDD (Mocha, Nightwatch / Selenium)',
    rel: 'nofollow',
    url: 'https://photoeditorsdk.com'
  },
  {
    image: '/images/projects/filsh.png',
    name: 'FILSH.net',
    date: '2007 - 2017',
    description: 'FILSH.net is a platform that allows you to download and convert videos from various websites such as YouTube, Vimeo and more. I sold it in 2017 as I wanted to focus on other projects.',
    tools: 'Node.JS, MySQL, Redis, FFmpeg, Backbone.JS'
  }
]

export default class ProjectsComponent extends React.Component<IProps, {}> {
  private _renderProjects (): JSX.Element[] {
    return ProjectData.map(p => {
      return (<Project key={p.name}>
        <Image src={p.image} />
        <Head>
          <Name>{p.name}</Name>
          <Date>{p.date}</Date>
        </Head>
        <Description>{p.description}</Description>
        {p.tools && <Detail>
          <DetailLabel>Tools:</DetailLabel>
          {p.tools}
        </Detail>}
        {p.url && <Detail>
          <DetailLabel>URL:</DetailLabel>
          <URL href={p.url} rel={p.rel} target='_blank'>{p.url}</URL>
        </Detail>}
      </Project>)
    })
  }

  render () {
    return (<Container>
      <Content>
        <Headline>Projects</Headline>
        <Projects>
          {this._renderProjects()}
        </Projects>
      </Content>
    </Container>)
  }
}

const Container = styled.div`
  ${ContentContainerStyle};
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 50px 0;
`

const Headline = styled.h1`
  ${HeadlineStyle};
  color: ${props => props.theme.textColor};
  margin-bottom: 30px;
`

const Content = styled.div`
  max-width: 1270px;
  width: 100%;
  color: ${props => props.theme.textColor};
  letter-spacing: 1px;
`

const Projects = styled.div`
  margin-left: -23px;
  ${Utils.mobileBreakpoint(`
    margin-left: 0;
  `)}
`

const Project = styled.div`
  width: 235px;
  margin: 0 23px;
  display: inline-block;
  vertical-align: top;
  font-family: 'Avenir', sans-serif;
  font-size: 12px;
  margin-bottom: 50px;
  ${Utils.mobileBreakpoint(`
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
  `)}
`

const Image = styled.img`
  margin-bottom: 10px;
  max-width: 235px;
`
const Head = styled.div`
  margin-bottom: 17px;
`

const Name = styled.div`
  font-weight: 900;
  font-size: 16px;
`
const Date = styled.div`
  font-size: 12px;
`
const Description = styled.div`
  margin-bottom: 17px;
`
const Detail = styled.div``
const DetailLabel = styled.div`
  display: inline-block;
  font-weight: 900;
  margin-right: 5px;
`
const URL = styled.a`
  color: ${props => props.theme.greenColor};
`
