import * as React from 'react'
import styled from '../../styled-components'
import NavigationComponent from './NavigationComponent'
import { ContentContainerStyle } from '../../styles'
import Utils from '../../lib/Utils'

interface IProps {

}

export default class HeaderComponent extends React.Component<IProps, {}> {
  render () {
    return (<Container>
      <Logo src='/images/logo.svg' alt='sash.wtf - web, app and game development from bochum' />
      <NavigationComponent />
    </Container>)
  }
}

const Container = styled.div`
  ${ContentContainerStyle};
  position: relative;
  flex: 0 0 auto;
  ${Utils.mobileBreakpoint(`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding: 20px 20px 0 20px;
    margin-bottom: 30px;
  `)}
`

const Logo = styled.img`
  width: 82px;
  margin: 30px 40px;
  ${Utils.mobileBreakpoint(`
    margin: auto;
    margin-bottom: 20px;
  `)}
`
