import * as React from 'react'
import { Route } from 'mobx-router'
import HomeComponent from '../components/pages/home/HomeComponent'
import SkillsComponent from '../components/pages/home/SkillsComponent'
import ProjectsComponent from '../components/pages/home/ProjectsComponent'
import ImprintComponent from '../components/pages/home/ImprintComponent'
import PrivacyPolicyComponent from '../components/pages/home/PrivacyPolicyComponent'

declare var ga: any

const onEnter = (route: any) => {
  ga('send', 'pageview', route.path)
}

export default {
  home: new Route({
    path: '/',
    component: <HomeComponent />,
    onEnter
  }),
  skills: new Route({
    path: '/skills',
    component: <SkillsComponent />,
    onEnter
  }),
  projects: new Route({
    path: '/projects',
    component: <ProjectsComponent />,
    onEnter
  }),
  imprint: new Route({
    path: '/imprint',
    component: <ImprintComponent />,
    onEnter
  }),
  privacyPolicy: new Route({
    path: '/privacy-policy',
    component: <PrivacyPolicyComponent />,
    onEnter
  })
}
