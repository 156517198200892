import 'core-js/es6/promise'
import 'formdata-polyfill'

import './index.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import { startRouter } from 'mobx-router'

import Store from './Store'
import views from './config/views'
import AppComponent from './components/AppComponent'
import MainTheme from './themes/MainTheme'
import { ThemeProvider } from './styled-components'

const store = new Store()

startRouter(views, store);

ReactDOM.render(<Provider store={store}>
  <ThemeProvider theme={MainTheme}>
    <AppComponent />
  </ThemeProvider>
</Provider>, document.getElementById('root'));
