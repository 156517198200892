import * as styledComponents from 'styled-components'
import { ThemedStyledComponentsModule } from 'styled-components'

import { IMainTheme } from './themes/MainTheme'

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  withTheme
} = styledComponents as ThemedStyledComponentsModule<IMainTheme>

export { css, createGlobalStyle, keyframes, ThemeProvider, withTheme }
export default styled
