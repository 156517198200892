import * as React from 'react'
import { Route, goTo } from 'mobx-router'
import styled from '../../styled-components'
import views from '../../config/views'
import { inject, observer } from 'mobx-react'
import Store from '../../Store'
import Utils from '../../lib/Utils'

interface IProps {
  store?: Store
}

@inject('store')
@observer
export default class NavigationComponent extends React.Component<IProps, {}> {
  // @ts-ignore
  private _onItemClick (route: Route, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault()
    this.props.store!.router.goTo(route)
  }

  render () {
    const { currentView } = this.props.store!.router
    return (<Container>
      <Item className={currentView == views.home ? 'is-active' : ''} href={views.home.path} onClick={this._onItemClick.bind(this, views.home)}>Home</Item>
      <Item className={currentView == views.skills ? 'is-active' : ''} href={views.skills.path} onClick={this._onItemClick.bind(this, views.skills)}>Skills</Item>
      <Item className={currentView == views.projects ? 'is-active' : ''} href={views.projects.path} onClick={this._onItemClick.bind(this, views.projects)}>Projects</Item>
      <Item href='/resume.pdf'>Resume</Item>
      <RoundedItem href='mailto:hello@sash.wtf'>Contact Me</RoundedItem>
    </Container>)
  }
}

const Container = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 30px;
  
  ${Utils.mobileBreakpoint(`
    position: static;
  `)}
`

const Item = styled.a`
  font-family: 'Avenir', sans-serif;
  font-size: 11px;
  font-weight: 700;
  color: ${props => props.theme.textColor};
  text-transform: uppercase;
  letter-spacing: 1.5px;
  padding: 10px 15px;
  cursor: pointer;
  text-decoration: none;
   
  &:hover, &.is-active {
    position: relative;
    color: ${props => props.theme.greenColor};
    font-family: 'Avenir', sans-serif;
    font-size: 11px;
    font-weight: 700;
    &:before, &:after {
      display: block;
      position: absolute;
      top: 10px;
      color: ${props => props.theme.greenColor};
    }
    
    &:before {
      content: '<';
      left: 5px;
    }
    
    &:after {
      content: '>';
      right: 5px;
    }
  }
`

const RoundedItem = styled.a`
  background: ${props => props.theme.textColor};
  color: ${props => props.theme.backgroundColor};
  border-radius: 8px;
  font-family: 'Avenir', sans-serif;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  padding: 5px 11px;
  cursor: pointer;
  margin-left: 10px;
  text-decoration: none;
  
  ${Utils.mobileBreakpoint(`
    display: block;
    margin-top: 20px;
  `)}
`
