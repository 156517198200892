import * as React from 'react'
import styled from '../../../styled-components'
import { ContentContainerStyle, HeadlineStyle } from '../../../styles'

interface IProps {

}

export default class PrivacyPolicyComponent extends React.Component<IProps, {}> {
  render () {
    return (<Container>
      <Content>
        <Headline>Privacy Policy</Headline>

        <h2>Server-Log-Files</h2> <p>Der Provider der Seiten erhebt
        und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch
        an uns &uuml;bermittelt. Dies sind:</p>
        <ul>
          <li>Browsertyp und Browserversion</li>
          <li>verwendetes
            Betriebssystem
          </li>
          <li>Referrer URL</li>
          <li>Hostname des zugreifenden Rechners</li>
          <li>Uhrzeit der
            Serveranfrage
          </li>
        </ul>
        <p>Diese Daten sind nicht bestimmten Personen zuordenbar. Eine
          Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten
          uns vor, diese Daten nachtr&auml;glich zu pr&uuml;fen, wenn uns konkrete Anhaltspunkte f&uuml;r eine
          rechtswidrige Nutzung bekannt werden.</p><p>&nbsp;</p> <h2>Google Analytics</h2> <p>Diese
        Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc., 1600
        Amphitheatre Parkway Mountain View, CA 94043, USA.</p> <p>Google Analytics verwendet so genannte
        &quot;Cookies&quot;. Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine
        Analyse der Benutzung der Website durch Sie erm&ouml;glichen. Die durch den Cookie erzeugten
        Informationen &uuml;ber Ihre Benutzung dieser Website werden in der Regel an einen Server von Google
        in den USA &uuml;bertragen und dort gespeichert.</p> <p><strong>Browser Plugin</strong></p> <p>Sie
        k&ouml;nnen die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
        verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht s&auml;mtliche
        Funktionen dieser Website vollumf&auml;nglich werden nutzen k&ouml;nnen. Sie k&ouml;nnen
        dar&uuml;ber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung der Website
        bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
        verhindern, indem Sie das unter dem folgenden Link verf&uuml;gbare Browser-Plugin herunterladen und
        installieren: <Link href="https://tools.google.com/dlpage/gaoptout?hl=de" className="link">
          https://tools.google.com/dlpage/gaoptout?hl=de</Link></p> <p><strong>Widerspruch gegen
        Datenerfassung</strong></p> <p>Sie k&ouml;nnen die Erfassung Ihrer Daten durch Google Analytics
        verhindern, indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung
        Ihrer Daten bei zuk&uuml;nftigen Besuchen dieser Website verhindert: <Link href="javascript:gaOptout();"
                                                                                className="link">
          Google Analytics deaktivieren</Link></p> <p>Mehr Informationen zum Umgang mit Nutzerdaten bei Google
        Analytics finden Sie in der Datenschutzerkl&auml;rung von Google: <Link
          href="https://support.google.com/analytics/answer/6004245?hl=de" className="link">

          https://support.google.com/analytics/answer/6004245?hl=de</Link></p><p>&nbsp;</p> <h2>SSL-
        Verschl&uuml;sselung</h2> <p>Diese Seite nutzt aus Gr&uuml;nden der Sicherheit und zum Schutz der

        &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als Seitenbetreiber
        senden, eine SSL-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung erkennen Sie daran, dass
        die Adresszeile des Browsers von &quot;http://&quot; auf &quot;https://&quot; wechselt und an dem

        Schloss-Symbol in Ihrer Browserzeile.</p> <p>Wenn die SSL Verschl&uuml;sselung aktiviert ist,
        k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen werden.</p>
      </Content>
    </Container>)
  }
}

const Container = styled.div`
  ${ContentContainerStyle};
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 50px 0;
  font-family: 'Avenir', sans-serif;
  color: ${props => props.theme.textColor};
`

const Content = styled.div`
  max-width: 1270px;
  width: 100%;
  color: ${props => props.theme.textColor};
  letter-spacing: 1px;
`

const Headline = styled.h1`
  ${HeadlineStyle};
  color: ${props => props.theme.textColor};
  margin-bottom: 30px;
`

const Link = styled.a`
  color: ${props => props.theme.textColor};
`
